/**
 * External Dependencies
 */
import React, { Component } from 'react';

/**
 * Internal Dependencies
 */
import AsyncComponent from '../../components/async-component';
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import Cookies from 'js-cookie';

/**
 * Component
 */
class DeletedEmployee extends Component {
    render() {
        const deleted_employee_view = Cookies.get('deleted_employee_view');
        // const employee_page = Cookies.get('employee_page');
        console.log("deleted_employee_view",deleted_employee_view);
        return (
            <PageWrap>
              
                <div style={{display:deleted_employee_view == "true"? "block":"none"}}>
                {/* <div style={{display:deleted_employee_view == "true" && employee_page == "true" ? "block":"none"}}> */}
                    <AsyncComponent component={ () => import( './content' ) } />
                </div>
            </PageWrap>
        );
    }
}

export default DeletedEmployee;
